.dropdown-grid {
  display: inline-block;
  user-select: none;
  position: relative;
}

.dropdown-grid .clickable-area {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 32px;
  width: 64px;
  align-items: center;
  border: solid 1px #dee2e6;
  border-radius: 2px;
  background-color: #ffffff;
  cursor: pointer;
}

.dropdown-grid .clickable-area .selected-value {
  text-align: center;
  flex-grow: 1;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #404040;
}

.dropdown-grid .clickable-area .arrow {
  fill: #22b3b3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 8px;
}

.dropdown-grid .menu {
  display: none;
  position: absolute;
  margin: 4px;
  background-color: white;
  min-width: 228px;
  box-shadow: 0px 4px 32px 0 rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  z-index: 1;
  flex-direction: row;
}

.dropdown-grid .menu .menu-col {
  flex-grow: 1;
}

.dropdown-grid .menu .menu-col .menu-item {
  padding: 16px;
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #5c7080;
  cursor: pointer;
}

.dropdown-grid .menu .menu-col .selected {
  font-weight: 500;
  color: #22b3b3;
}

.dropdown-grid .clickable-area .initial {
  animation: rotate-initial 0.5s ease;
}

.dropdown-grid .clickable-area .rotate {
  animation: rotate 0.5s ease;
  transform: rotate(180deg);
}

.dropdown-grid .hide {
  animation: hide-menu 0.5s ease;
}

.dropdown-grid .show {
  animation: show-menu 0.5s ease;
  display: flex;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-180deg);
  }
}

@keyframes rotate-initial {
  from {
    transform: rotate(-180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes hide-menu {
  from {
    visibility: visible;
    opacity: 1;
  }

  to {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes show-menu {
  from {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}
