.edit-alert {
  background: #f4f5f7;
}

.edit-alert td {
  border-top: 1px solid #19838C;
  border-bottom: 1px solid #19838C;
  border-left: 0;
  border-right: 0;
  vertical-align: top;
  padding-bottom: 20px;
  padding-top: 30px;
}

.edit-alert .action-column {
  padding-top: 22px;
}

.edit-alert td:first-child {
  border-left: 5px solid #19838C;
}

.edit-alert td:last-child {
  border-right: 1px solid #19838C;
}

.edit-alert .label {
  font-size: 13px;
  font-weight: 500;
  color: #606060;
  margin-bottom: 19px;
  margin-top: 29px;
}

.edit-alert .column {
  padding: 0 3px 0 3px;
}

.first {
  padding-left: 5px;
}

.first .column {
  padding-left: 0;
}

.intent-danger {
  font-family: "Open Sans";
  margin-top: 5px;
  font-size: 12px;
  color: red;
}

.action-buttons {
  display: flex;
  flex-direction: column;
}

.action-buttons-container {
  display: flex;
  justify-content: center;
}
