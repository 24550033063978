.manage-cameras-page-header {
  display: flex;
  background-color: white;
  height: 54px;
  background-color: #ffffff;
  user-select: none;
  position: relative;
  z-index: 1;
  background-color: #f6f6f6;
}
.manage-cameras-page-header .manage-cameras-tabs {
  margin: 7px 8px 0 8px;
  height: 48px;
}

.manage-cameras-add-button {
  margin-right: 8px;
  margin-left: 4px;
}

.manage-cameras-filter-inputs {
  margin-left: auto;
  padding-top: 10px;
  display: flex;
  flex-grow: 1;
  justify-content: end;
}

.manage-cameras-input-container {
  width: 100%;
  margin-right: 8px;
  display: flex;
  justify-content: end;
  max-width: 265px;
}
