.text-cell {
  height: 60px;
  display: flex;
  align-items: center;
}

.text-cell-container {
  width: 100%;
  display: block;
}
