.input-icon-field-browse-style {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  margin: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 54px;
  background-color: white;
}

.input-icon-field-selected-file {
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
}

.input-icon-field-browse {
  width: 100%;
  overflow: hidden;
  color: #173544;
  fill: #173544;
}

.input-icon-field-input-browse-button {
  display: none;
}

.input-icon-field-action-icon-div {
  display: inline-block;
  cursor: pointer;
  font: normal normal normal 12px/14px "Open Sans";
  letter-spacing: 1.2px;
  color: #173544;
}

.input-icon-field-button {
  display: flex;
  align-items: center;
}

.input-icon-field-action-icon {
  fill: #173544;
  height: 13px;
  cursor: pointer;
  margin-right: 1px;
  width: 20px;
  height: 20px;
}

.input-icon-field-action-icon.input-icon-delete {
  fill: #b33322;
}

.Mui-disabled .input-icon-field-action-icon.input-icon-delete {
  fill: #9E9E9E;
}

.svg-icon {
  width: 13px;
  height: 13px;
  margin-right: 7px;
}

.input-icon-field-edit {
  height: 100%;
}

.input-icon-field-browse-style .MuiButtonBase-root {
  height: 100%;
}

.input-icon-field-button-container {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  margin-top: 3px;
}

.input-icon-field-button-container .MuiButtonBase-root {
  font: normal normal normal 12px/14px "Open Sans";
  letter-spacing: 1.2px;
  color: #173544;
}

.input-icon-field-button-container .MuiButton-startIcon {
  margin-right: 0;
}
