.login-button {
  width: 280px;
  height: 50px;
  background-color: #173544;
  border-radius: 4px;
  margin-bottom: 57px;
}

.login-button :global(.simple-button) {
  background-color: #173544;
}

.login-button :global(.simple-button .button-text) {
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}

.login-button :global(.simple-button.disabled) {
  background-color: #d0d0d0;
}
