.flex-container {
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}

.settings-odd-tab {
  display: flex;
  padding-left: 48px;
}

.item {
  width: 100%;
  mix-blend-mode: undefined;
  background-color: #ffffff;
  border: solid 1px rgba(215, 215, 215, 0.38);
}

.odd-tab {
  height: 68px;
  background-color: #edf0f2;
}

.settings-col-1 {
  width: 35%;
  float: left;
  margin-top: auto;
  margin-bottom: auto;
}

.settings-col-2 {
  width: 35%;
  float: left;
  margin-top: auto;
  margin-bottom: auto;
}

.settings-col-3 {
  width: 30%;
  float: left;
  margin-top: auto;
  margin-bottom: auto;
}

.settings-title {
  font-weight: 500;
  font-stretch: normal;
  color: #606060;
  margin-top: auto;
  margin-bottom: auto;
}

.settings-even-tab {
  display: contents;
}

.even-tab {
  display: flex;
  padding-left: 48px;
  min-height: 60px;
  mix-blend-mode: undefined;
  background-color: #ffffff;
  border: solid 1px rgba(215, 215, 215, 0.38);
}

.settings-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: #404040;
  margin-top: auto;
  margin-bottom: auto;
}

.switch-container {
  display: flex;
  margin: -12px;
}
