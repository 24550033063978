.layout-footer {
  height: 40px;
  min-height: 40px;
  width: 100%;
  border: 1px solid #d0d0d0;
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.layout-footer .footer-logo {
  padding-left: 20px;
  width: 50px;
  height: 10px;
}

.layout-footer .footer-logo svg {
  fill: #e10a19;
  display: block;
}

.layout-footer .about-button {
  font-size: 14px;
  line-height: 19px;
  user-select: none;
  cursor: pointer;
  color: #22b3b3;
  margin-right: 20px;
}

.layout-footer-warning-message {
  padding-left: 5px;
  font: normal normal normal 10px/16px "Open Sans";
  letter-spacing: 0.36px;
  color: #ff0000;
  white-space: nowrap;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.layout-footer-warning-content {
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 0 10px 0 10px;
}

.layout-footer-warning-content svg {
  flex-shrink: 0;
}
