.tabs-container {
  width: 100%;
}

.item {
  width: 100%;
  mix-blend-mode: undefined;
  background-color: #ffffff;
  border: solid 1px rgba(215, 215, 215, 0.38);
}

.odd-tab {
  height: 68px;
  background-color: #edf0f2;
}

.title {
  position: inherit;
  padding: 24px 24px 24px 48px;
  font-weight: 500;
  font-size: inherit;
  letter-spacing: 0.4px;
  font-stretch: normal;
  color: #606060;
  top: 30px;
  left: 46px;
  line-height: normal;
}

.even-tab {
  display: flex;
  padding-left: 48px;
  min-height: 60px;
  mix-blend-mode: undefined;
  background-color: #ffffff;
  border: solid 1px rgba(215, 215, 215, 0.38);
}

.col-3 {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.toggle-button {
  object-fit: contain;
}

.col-1 {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.text {
  margin: auto 0;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.4px;
  font-stretch: normal;
  color: #606060;
  white-space: nowrap;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  -webkit-text-fill-color: #d3d3d3;
}

.col-2 {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.col-4 {
  padding: 10px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row-1 {
  min-height: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  align-items: start;
}

.row-1.edit-text {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.edit-day-time-icon {
  fill: #22b3b3;
  min-width: 24px;
  cursor: pointer;
}

.edit-day-time-text {
  color: #22b3b3;
  cursor: pointer;
  margin-right: 30px;
}

.switch-container {
  display: flex;
  margin: -12px;
}
