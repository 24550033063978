.sort-header {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #2f2f2f;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0px;
  width: 100%;
  cursor: pointer;
  user-select: none;
}

.sort-header.locked {
  cursor: default;
}

.sort-header.center {
  justify-content: center;
}

.sort-header.right {
  justify-content: end;
}

.sort-header.left {
  justify-content: start;
}

.sort-header.selected {
  color: #22b3b3;
  font-weight: bold;
}

.sort-header-text-container .sort-header-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sort-header .icon-container {
  width: 33px;
}

.sort-header .sort-header-icon .sort-icons-container svg {
  padding-right: 3px;
}

.sort-header .sort-header-icon .sort-icons-container {
  width: 25px;
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.sort-header svg .a {
  fill: black;
  stroke: black;
}
.sort-header.selected svg .a {
  fill: #22b3b3;
  stroke: #22b3b3;
}

.header-container {
  overflow: hidden;
}
