.back-button :global(.MuiButton-root) {
    background-color: #19838c;
    font: normal normal 600 14px/16px "Open Sans";
    letter-spacing: 1.25px;
    white-space: nowrap;
}

.back-button :global(.MuiButton-root):hover {
    background-color: #19838c;
}

.back-button svg {
    fill: white;
    width: 24px;
}