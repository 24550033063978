.sidemenu-container {
  background-color: #173544;
}

.sidemenu-container .nav-container {
  width: 55px;
  height: 100%;
  position: relative;
}

.sidemenu-container .nav-container.expanded {
  width: 250px;
}

.sidemenu-expand-button {
  position: absolute;
  bottom: 10px;
  right: 0px;
}