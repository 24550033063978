:global(.status-icon) {
  width: 40px;
  height: 40px;
}

.default svg {
  fill: #153544;
}

.inactive svg {
  fill: #b8b8b8;
}

.instant svg {
  color: #f73b0f;
  fill: #f73b0f;
}

.delayed svg {
  color: #f4ae22;
  fill: #f4ae22;
}

.nursePresent svg {
  color: #35cf0b;
  fill: #35cf0b;
}

.custom-icon {
  width: 100%;
  height: 100%;
}

.disabled svg {
  opacity: 0.4;
}
