.status-icon {
  margin-right: 5px;
  fill: #153544;
  display: flex;
  align-items: center;
  user-select: none;
}

.status-icon .inactive {
  fill: #b8b8b8;
}

.status-icon .instant {
  color: #f73b0f;
  fill: #f73b0f;
}

.status-icon .delayed {
  color: #f4ae22;
  fill: #f4ae22;
}

.status-icon .nursePresent {
  color: #35cf0b;
  fill: #35cf0b;
}

