.save-button {
  margin-right: 10px;
}

.title {
  font-size: 25px;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #212121;
  position: absolute;
  top: 30px;
  left: 46px;
}

.client-name {
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: #22b3b3;
  position: absolute;
  top: 70px;
  left: 46px;
}

.client-area {
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: #b2b2b2;
  position: absolute;
  top: 90px;
  left: 46px;
}

.client-room {
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: #404040;
}

.container {
  position: absolute;
  right: 32px;
  top: 22px;
  display: flex;
}

.header-button {
  min-width: 120px;
  height: 40px;
}

.header-button :global(.MuiButton-root) {
  background-color: #19838c;
  font: normal normal 600 14px/16px "Open Sans";
  letter-spacing: 1.25px;
  white-space: nowrap;
  color: white;
  min-width: 120px;
  height: 40px;
}

.header-button :global(.MuiButton-root):hover {
  background-color: #19838c;
}

.header-button svg {
  fill: white;
  width: 24px;
}
