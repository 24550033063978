.logs-view {
    margin-left: 9px;
    max-height: 693px;
    height: 612px;
    margin-top: 28px;
}

@media (min-height: 680px) {
    .logs-view {
        margin-left: 9px;
        max-height: 693px;
    }
}