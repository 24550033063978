.alarmSettingsCell svg {
  width: 25px;
  height: 25px;
}

svg.active {
  fill: #f73b0f;
}

svg.inactive {
  fill: #b7b7b7;
}

svg.delayed {
  fill: #f4ae22;
}
