.manage-substatuses-page-header {
  display: flex;
  background-color: #f6f6f6;
  height: 54px;
  user-select: none;
  position: relative;
  z-index: 2;
}

.manage-substatuses-page-header .manage-substatuses-tabs {
  margin: 7px 8px 0 8px;
  height: 48px;
}

.manage-substatuses-filter-inputs {
  margin-left: auto;
  padding-top: 10px;
  display: flex;
  flex-grow: 1;
  justify-content: end;
}

.manage-substatuses-input-container {
  width: 100%;
  margin-right: 8px;
  display: flex;
  justify-content: end;
  max-width: 265px;
}

.manage-substatuses-add-button {
  margin-right: 8px;
  margin-left: 4px;
}
