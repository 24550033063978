.camera-location {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  line-height: initial;
  white-space: nowrap;
}

.location-path {
  font-size: 14px;
  letter-spacing: 0.4px;
  text-align: left;
  color: #b2b2b2;
  text-overflow: ellipsis;
  overflow: hidden;
}

.location-name {
  font-size: 14px;
  letter-spacing: 0.4px;
  text-align: left;
  color: #404040;
  vertical-align: text-top;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dash-icon {
  width: 40px;
  color: #173544;
}
