.sensor-issue {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
}

.sensor-issue.clickable {
  cursor: pointer;
}

.sensor-issues-icon {
  height: 40px;
  width: 40px;
}

.text {
  margin: auto 0;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.4px;
  font-stretch: normal;
  color: #606060;
  white-space: nowrap;
  user-select: none;
}

.critical {
  fill: #f73b0f;
}

.warning {
  fill: #f4ae22;
}

.text.spaced {
  padding-left: 10px;
}

.col-4 {
  padding: 10px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tabs-container {
  width: 100%;
}

.item {
  width: 100%;
  mix-blend-mode: undefined;
  background-color: #ffffff;
  border: solid 1px rgba(215, 215, 215, 0.38);
}

.odd-tab {
  height: 68px;
  background-color: #edf0f2;
}

.title {
  position: inherit;
  padding: 24px 24px 24px 48px;
  font-weight: 500;
  font-size: inherit;
  letter-spacing: 0.4px;
  font-stretch: normal;
  color: #606060;
  top: 30px;
  left: 46px;
  line-height: normal;
}

.even-tab {
  display: flex;
  padding-left: 48px;
  min-height: 60px;
  mix-blend-mode: undefined;
  background-color: #ffffff;
  border: solid 1px rgba(215, 215, 215, 0.38);
}

.col-1 {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.col-234 {
  width: 75%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}
