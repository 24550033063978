.selected-client {
  background-color: #eafbfb !important;
}

.selected-client td {
  border-top: solid 1px rgba(21, 53, 68, 0.38) !important;
  border-bottom: solid 1px rgba(21, 53, 68, 0.38) !important;
  border-left: 0;
  border-right: 0;
  padding-left: 3px;
  padding-right: 3px;
}

.selected-client td:first-child {
  padding-left: 0;
}

.selected-client td:last-child {
  padding-right: 0;
}
