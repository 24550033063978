.alarm-settings-header-icon {
  margin-left: 10px;
  margin-right: 10px;
  height: 16px;
  width: 16px;
}

.alert-settings-header-container {
  display: flex;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.round:before {
  border-radius: 50%;
}

.slider.round {
  border-radius: 20px;
}

input:checked + .slider.day {
  background-color: #22b3b3;
}

input:focus + .slider.day {
  box-shadow: 0 0 1px #22b3b3;
}

input + .slider.night {
  background-color: #e29463;
}

input:focus + .slider.night {
  box-shadow: 0 0 1px #e29463;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}
