.sensor-name {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-align: left;
  color: #404040;
  text-transform: none;
}

.sensor-room {
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: center;
  line-height: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.room {
  color: #404040;
  font-size: 14px;
  letter-spacing: 0.4px;
  text-align: left;
  text-transform: none;
  text-overflow: ellipsis;
  overflow: hidden;
}

.area {
  color: #b2b2b2;
  font-size: 14px;
  letter-spacing: 0.4px;
  margin-right: 10px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
}

.selected-sensor {
  background-color: #eafbfb !important;
}

.selected-sensor td {
  border-top: solid 1px rgba(21, 53, 68, 0.38) !important;
  border-bottom: solid 1px rgba(21, 53, 68, 0.38) !important;
  border-left: 0;
  border-right: 0;
  padding-left: 3px;
  padding-right: 3px;
}

.selected-sensor td:first-child {
  padding-left: 0;
}

.selected-sensor td:last-child {
  padding-right: 0;
}

.no-location-field {
  font-style: italic;
  color: #b2b2b2;
  font-size: 14px;
  white-space: break-spaces;
}
