.clients-overview-header {
  width: 100%;
  height: 54px;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: row;
  z-index: 1000;
  position: relative;
}

.clients-overview-header .clients-overview-tabs {
  margin: 7px 8px 0 8px;
  height: 48px;
}

.clients-overview-filter-inputs {
  margin-left: auto;
  padding-top: 10px;
  display: flex;
  flex-grow: 1;
  justify-content: end;
}

.clients-overview-header .clients-overview-search {
  width: 100%;
  margin-right: 8px;
  display: flex;
  justify-content: end;
  max-width: 265px;
}
