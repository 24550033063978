.settings-even-tab {
  display: contents;
}

.even-tab {
  display: flex;
  padding-left: 48px;
  min-height: 60px;
  mix-blend-mode: undefined;
  background-color: #ffffff;
  border: solid 1px rgba(215, 215, 215, 0.38);
}

.item {
  width: 100%;
  mix-blend-mode: undefined;
  background-color: #ffffff;
  border: solid 1px rgba(215, 215, 215, 0.38);
}

.settings-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: #404040;
  margin-top: auto;
  margin-bottom: auto;
}

.settings-col-1 {
  width: 20%;
  float: left;
  padding-right: 40px;
}

.settings-col-2 {
  width: 20%;
  float: left;
  margin: auto 0;
}

.settings-col-3 {
  width: 47%;
  float: left;
}

.settings-col-4 {
  width: 10%;
  float: left;
}

.switch-container {
  display: flex;
  margin: -12px;
}
