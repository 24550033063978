table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
}

table tr.odd {
  background-color: #f6f6f6;
}

table tr.even {
  background-color: white;
}

table tr td {
  padding: 0;
  border-left: 3px solid white;
  border-right: 3px solid white;
  box-sizing: border-box;
}
table tr td:nth-child(1) {
  border-left: 0;
}

table tr td:last-child {
  border-right: 0;
}

.insert {
  height: 50px;
  background-color: grey;
  color: white;
}

.tab-header {
  background-color: white;
  height: 40px;
  color: #2f2f2f;
}

.tab-header th {
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  letter-spacing: 0px;
  padding-left: 3px;
  padding-right: 3px;
  border-bottom: 1px solid #D0D0D0;
}

.table-cell-container {
  display: block;
  margin: 0 10px 0 10px;
  max-width: 100%;
}

table tr .firstColumn {
  margin: 0 10px 0 26px;
}

table .firstColumn .header-cell-container {
  margin: 0 10px 0 23px;
}

.header-cell-container {
  display: block;
  margin: 0 10px 0 10px;
  max-width: 100%;
}

tr.expanded-row td {
  border-left: 0px solid white;
  border-right: 0px solid white;
}
