.daily-view-container {
  height: 100%;
  user-select: none;
}

.daily-view-container .daily-chart {
  height: 8%;
  width: 100%;
  flex-grow: 2;
}

.daily-view-container .chart-info {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  margin-top: 14px;
  margin-bottom: 2px;
}

.daily-view-container .chart-info .daily-view-title {
  margin-left: 17px;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  color: #606060;
  margin-right: 32px;
}

.daily-view-container .chart-info .date {
  margin-left: 10px;
  margin-right: 10px;
  font-family: "Open Sans";
  font-size: 12px;
  text-align: center;
  min-width: 112px;
  letter-spacing: 0.4;
  color: #404040;
  user-select: none;
}

.daily-view-container .chart-info .today {
  text-transform: uppercase;
  font-family: "Open Sans";
  font-size: 13px;
  padding: 8px;
  margin-left: 16px;
  font-weight: 500;
  user-select: none;
  cursor: pointer;
  color: #c7c8c9;
}

.chart-info svg {
  width: 16px;
  height: 16px;
  padding: 4px;
  cursor: pointer;
  fill: #c7c8c9;
}

.daily-view-container .chart-info svg.arrow-left {
  transform: rotate(90deg);
}

.daily-view-container .chart-info svg.arrow-right {
  transform: rotate(-90deg);
}

.daily-view-container .chart-info .available {
  fill: #22b3b3;
  color: #22b3b3;
}
