.client-profile {
  display: flex;
  flex-direction: row;
}

.client-profile-icon {
  cursor: pointer;
}

.client-profile .profile-text {
  margin-right: 35px;
  white-space: pre-wrap;
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: #606060;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
