.login-layout-footer {
  height: 40px;
  width: 100%;
  border: 1px solid #d0d0d0;
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.login-footer-logo {
  margin: 0 auto;
  width: 50px;
  height: 10px;
}

.login-footer-logo svg {
  fill: #e10a19;
  display: block;
}
