.client-status-cell {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.timestamp {
  color: #808080;
  font-size: 12;
  padding-left: 5px;
  margin-right: 5px;
  white-space: nowrap;
}

.status-text {
  display: flex;
  flex-direction: row;
  height: 20px;
  align-items: center;
}

.status-text.top-text {
  font-size: 12px;
  color: #000000;
}

.status-text.bottom-text {
  font-size: 12px;
  color: #000000;
}

.current-status {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.client-status-cell.red .status-text.top-text {
  color: #f73b0f;
  fill: #f73b0f;
}

.client-status-cell.orange .status-text.top-text {
  color: #f4ae22;
  fill: #f4ae22;
}

.client-status-cell.green .status-text.top-text {
  color: #35cf0b;
  fill: #35cf0b;
}

.client-status-cell :global(.instant) {
  color: #f73b0f;
  fill: #f73b0f;
}

.client-status-cell :global(.delayed) {
  color: #f4ae22;
  fill: #f4ae22;
}

.client-status-cell :global(.nursePresent) {
  color: #35cf0b;
  fill: #35cf0b;
}

.client-status-cell :global(.show-visitor-detected) {
  color: #d331dd;
  font-size: 12px;
  font-style: italic;
}

.client-status-cell :global(.show-nurse-present) {
  color: #35cf0b;
  font-size: 12px;
  font-style: italic;
}

.client-status-separator {
  padding: 0 3px 0 3px;
}

.show-location {
  color: #22b3b3;
  text-decoration: underline;
  cursor: pointer;
  display: block;
  font-size: 12px;
}

.status-cell-location {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.status-position {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.status-location {
  font-size: 14px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.status {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.show-current-status-bottom {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
