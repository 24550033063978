.actions-cell {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.actions-cell .action-button-icon {
  cursor: pointer;
  display: inline-block;
}

.actions-cell .actions-cell-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}

.actions-cell .action-button-icon.edit-button-icon.locked-action-button {
  cursor: default;
  fill: #e0e0e0;
}

.actions-cell .action-button-icon.delete-button-icon.locked-action-button {
  cursor: default;
  fill: #e0e0e0;
}

.actions-cell .action-button-icon.delete-button-icon {
  fill: #b33322;
}

.actions-cell .action-button-icon.edit-button-icon {
  fill: #19838c;
}

.actions-cell .action-text-button {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.actions-cell .action-text-button-icon {
  margin-right: 3px;
}

.actions-cell .cancel-button.action-text-button-icon {
  margin-left: 16px;
}

.actions-cell-button-container .edit-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.actions-cell .action-text-button.save-button-icon.locked-action-button .action-text-button-icon {
  fill: #e0e0e0;
  color: #e0e0e0;
}

.actions-cell .action-text-button.save-button-icon.locked-action-button {
  cursor: default;
}

.actions-cell .action-text-button.cancel-button-icon.locked-action-button .action-text-button-icon {
  fill: #e0e0e0;
  color: #e0e0e0;
}

.actions-cell .action-text-button.cancel-button-icon.locked-action-button {
  cursor: default;
}

.actions-cell .action-text-button.save-button-icon .action-text-button-icon {
  fill: #22b3b3;
  color: #22b3b3;
}

.actions-cell .action-text-button.cancel-button-icon .action-text-button-icon {
  fill: #8c9396;
  color: #8c9396;
}

.actions-cell .action-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-actions-button {
  width: 40px;
  height: 40px;
  margin: 0 1px 0 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}