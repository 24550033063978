.flex-container {
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}

.item {
  width: 100%;
  mix-blend-mode: undefined;
  background-color: #ffffff;
  border: solid 1px rgba(215, 215, 215, 0.38);
}

.odd-tab {
  height: 68px;
  background-color: #edf0f2;
}

.title {
  position: inherit;
  padding: 24px 24px 24px 48px;
  font-weight: 500;
  font-size: inherit;
  letter-spacing: 0.4px;
  font-stretch: normal;
  color: #606060;
}

.even-tab {
  display: flex;
  padding-left: 48px;
  min-height: 60px;
  mix-blend-mode: undefined;
  background-color: #ffffff;
  border: solid 1px rgba(215, 215, 215, 0.38);
}

.settings-col {
  width: 100%;
  padding: 16px 0;
  margin: auto 0;
}

.settings-radio {
  display: flex;
  padding: 0;
  margin: auto;
  padding-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.time-alerts {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #404040;
  padding: 0;
  cursor: pointer;
}

.radio-text {
  white-space: pre;
}

.selected-radio-text {
  white-space: pre;
  font-weight: bold;
  font-size: 15px;
}

.radio-input {
  height: 18px;
  margin: 0 5px 0 5px;
}
