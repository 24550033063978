.inactive-clients-delete-button {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  user-select: none;
  color: #d4554f;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}

.inactive-clients-delete-button svg {
  fill: #d4554f;
}

.inactive-clients-delete-button.locked {
  color: gray;
  cursor: default;
}

.inactive-clients-delete-button.locked svg {
  fill: gray;
}
