.manage-sensors-grid-container {
  height: calc(100% - 62px);
  overflow: auto;
  overflow-x: hidden;
  margin: 0 8px 6px 8px;
  border: 1px solid #d0d0d0;
}

.manage-sensors-grid-container :global(.firstColumn .header-cell-container) {
  margin: 0 10px 0 10px;
}

.manage-sensors-grid-container :global(.table-cell-container.firstColumn) {
  margin: 0 10px 0 10px;
}

.overflow-content {
  overflow: auto;
  height: 100%;
}
