.flex-container {
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}

.item {
  width: 100%;
  mix-blend-mode: undefined;
  background-color: #ffffff;
  border: solid 1px rgba(215, 215, 215, 0.38);
}

.odd-tab {
  height: 68px;
  background-color: #edf0f2;
}

.title {
  position: inherit;
  padding: 24px 24px 24px 48px;
  font-weight: 500;
  font-size: inherit;
  letter-spacing: 0.4px;
  font-stretch: normal;
  color: #606060;
}

.settings-even-tab {
  display: contents;
}

.even-tab {
  display: flex;
  padding-left: 48px;
  min-height: 60px;
  mix-blend-mode: undefined;
  background-color: #ffffff;
  border: solid 1px rgba(215, 215, 215, 0.38);
}

.settings-odd-tab {
  display: flex;
  padding-left: 48px;
}

.settings-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: #404040;
  margin-top: auto;
  margin-bottom: auto;
}

.settings-title {
  font-weight: 500;
  font-stretch: normal;
  color: #606060;
  margin-top: auto;
  margin-bottom: auto;
}

.settings-col-1 {
  width: 20%;
  float: left;
  padding-right: 40px;
}

.settings-col-2 {
  width: 20%;
  float: left;
  margin: auto 0;
}

.settings-col-3 {
  width: 47%;
  float: left;
}

.settings-col-4 {
  width: 10%;
  float: left;
}

:global(.critical) {
  fill: #f73b0f;
}
