.tab-button {
  color: #182026;
  font-size: 13px;
  cursor: pointer;
  user-select: none;
}

.tab-button.selected {
  color: #22b3b3;
}

.tab-button:hover {
  color: #22b3b3;
}

.tab-buttons-container {
  display: flex;
  flex-direction: row;
  margin-left: 15px;
  margin-right: 15px;
}

.tabs-separator {
  padding-left: 20px;
  padding-right: 20px;
}

.underline {
  width: 0px;
  height: 2px;
  background-color: #22b3b3;
  transform: translate(width);
  transition-duration: 0.5s;
  transform: translate(-50%);
  left: 50%;
  position: relative;
  margin-top: 3px;
}

.underline.selected {
  width: 100%;
}
