.sensor-status {
  color: #404040;
  fill: #404040;
  display: flex;
  align-items: center;
}

.sensor-status .status-icon {
  min-height: 40px;
  min-width: 40px;
  height: 40px;
  width: 40px;
  max-width: 40px;
  max-height: 40px;
  align-content: center;
  display: block;
}

.sensor-status .status-text {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sensor-status.red {
  color: #f73b0f;
  fill: #f73b0f;
}

.sensor-status.orange {
  color: #f4ae22;
  fill: #f4ae22;
}

.sensor-status.blue {
  color: #173544;
  fill: #173544;
}

.status-icon.red {
  color: #f73b0f;
  fill: #f73b0f;
}

.status-icon.orange {
  color: #f4ae22;
  fill: #f4ae22;
}

.status-icon.blue {
  color: #173544;
  fill: #173544;
}

.status-text-cell {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
}

.sensor-status.with-extra-info .status-text {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  white-space: pre-line;
}

.substatus-text {
  color: #f4ae22;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 1023px) and (orientation: portrait) {
  .sensor-status .text-visibility {
    visibility: hidden;
  }
}
