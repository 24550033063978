.manage-mari-browse-action-style {
  display: flex;
  cursor: pointer;
  white-space: nowrap;
}

.manage-mari-browse-button-text {
  color: #8c9396;
}

.manage-mari-upload-backup-browse-button {
  display: none;
}

.upload-icon {
  fill: #19838c;
}

.upload-icon.locked-upload-icon {
  cursor: default;
  fill: #e0e0e0;
}
