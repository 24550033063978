.edit-client {
  position: relative;
  background: #f4f5f7;
  border-top: 0;
}

.edit-client td {
  border: 0;
  border-bottom: 1px solid #19838C;
  padding-bottom: 30px;
  padding-top: 20px;
  vertical-align: top;
}

.edit-client .action-column {
  padding-top: 12px;
}

.edit-client.top-border td {
  border-top: 1px solid #19838C;
}

.edit-client.no-top-border td {
  border-top: 0;
}

.edit-client td:first-child {
  border-bottom: 1px solid #19838C;
  border-left: 5px solid #19838C;
}

.edit-client td:last-child {
  border-bottom: 1px solid #19838C;
  border-right: 1px solid #19838C;
}

.info-row-edit-client td:first-child {
  border: 1px solid #19838C;
  border-left: 5px solid #19838C;
  border-bottom: 0;
}

.edit-client .label {
  font-size: 13px;
  font-weight: 500;
  color: #606060;
  margin-bottom: 19px;
  margin-top: 29px;
}

.edit-client-warning {
  position: relative;
  background: #f4f5f7;
  padding-left: 42px;
  box-sizing: border-box;
  display: flex;
}

.edit-client-warning .label {
  padding-top: 30px;
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #b2b2b2;
  margin-right: 30px;
}

.edit-client-warning .warning-icon {
  margin-right: 12px;
  color: #22b3b3;
  padding-top: 30px;
  width: 21px;
  height: 16px;
  fill: #22b3b3;
}

.edit-client .column {
  padding: 0 3px 0 3px;
}

.edit-client .first {
  padding-left: 5px;
}

.first .column {
  padding-left: 0;
}

.intent-danger {
  font-family: "Open Sans";
  margin-top: 5px;
  font-size: 12px;
  color: red;
  height: 15px;
}

.action-buttons {
  display: flex;
  flex-direction: column;
}

.action-buttons-container {
  display: flex;
  justify-content: center;
}