.flex-container {
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}
