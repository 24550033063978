.layout-header {
  width: 100%;
  height: 60px;
  min-height: 60px;
  border: 1px solid #d0d0d0;
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.layout-header .title-container {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
}

.layout-header .title-container svg {
  cursor: pointer;
}

.layout-header .title-container .title {
  padding-left: 15px;
  user-select: none;
  font-family: "Open Sans";
  font-size: 25px;
  line-height: 34px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.layout-header .profile-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  padding: 0 20px 0 20px;
  height: 20px;
}

.layout-header .profile-container .profile-name {
  font-family: "Open Sans";
  font-size: 14px;
  padding-left: 4px;
  user-select: none;
  color: #000000;
  letter-spacing: 0px;
  opacity: 1;
  white-space: nowrap;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.layout-header .profile-container .profile-icon {
  width: 20px;
  height: 20px;
}

.layout-header .profile-container .profile-icon svg {
  fill: #19838C;
}

.layout-header .profile-container .separator {
  border-left: 1px solid #d0d0d0;
  width: 0px;
  height: 20px;
  margin-left: 13px;
  margin-right: 13px;
}

.layout-header .profile-container .logout-button {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 14px;
  line-height: 31px;
  letter-spacing: 0px;
  color: #19838C;
  opacity: 1;
  white-space: nowrap;
}
