.amstel-page-header {
    position: relative;
    height: 120px;
    background-color: #ffffff;
    box-shadow: 0px 4px 32px 0 rgba(0, 0, 0, 0.1);
    z-index: 2;
    user-select: none;
}


.mari-height {
    min-height: 134px;
    height: 134px;
}

@media (max-height: 700px) {
    .mari-height {
        min-height: 119px;
        height: 119px;
    }
}