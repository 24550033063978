.add-button :global(.MuiButton-root) {
  background-color: #19838c;
  font: normal normal 600 14px/16px "Open Sans";
  letter-spacing: 1.25px;
  white-space: nowrap;
}

.add-button :global(.MuiButton-root):hover {
  background-color: #19838c;
}

.add-button svg {
  fill: white;
  width: 24px;
}

.add-button :global(.MuiButton-root.Mui-disabled) {
  color: #9E9E9E;
  background-color: white;
  border: 1px solid #E6E6E6;
}

.add-button :global(.MuiButton-root.Mui-disabled) svg {
  fill: #9E9E9E;
  width: 24px;
}