.status-item {
  height: 50px;
  margin: 0;
  padding: 0;
  position: relative;
  background: rgba(221, 183, 13, 0);
  display: flex;
  margin-top: 10px;
  white-space: nowrap;
  color: #404040;
  fill: #404040;
}

.icon {
  height: 30px;
  width: 30px;
  top: 10px;
  padding-right: 3px;
}

.info-container {
  overflow: hidden;
  width: 100%;
}

.name {
  line-height: 15px;
  left: 42px;
  top: 10px;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: none;
}

.timestamp {
  line-height: 15px;
  left: 42px;
  top: 25px;
  color: #b2b2b2;
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  letter-spacing: 0.4px;
  text-align: left;
}
