.content-location {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  min-height: 20px;
}

.icon svg {
  fill: #173544;
  overflow: visible;
}

.icon.locked {
  cursor: not-allowed;
}

.icon.locked svg {
  fill: gray;
}

.location-text {
  font-size: 12px;
  font-family: "Open Sans";
  letter-spacing: 0.21px;
  color: #00000099;
  margin-left: 20px;
  margin-right: 5px;
  overflow: hidden;
}

.location-button {
  cursor: pointer;
  width: 14px;
  height: 20px;
  margin-right: 17px;
}

.loader {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 4px solid rgba(92, 112, 128, 0.2);
  border-top: 4px solid rgba(92, 112, 128, 0.8);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
