.text-field-input :global(label.Mui-focused) {
  color: black;
}

.text-field-input :global(.Mui-focused.MuiOutlinedInput-root fieldset) {
  border-color: black;
}

.text-field-input :global(.MuiOutlinedInput-root input) {
  padding: 16px 16px;
}

.text-field-input :global(.MuiOutlinedInput-root fieldset) {
  border-color: #666666;
}

.text-field-input :global(label.Mui-error) {
  color: #b33322;
}

.text-field-input :global(.Mui-error.MuiOutlinedInput-root fieldset) {
  border-color: #b33322;
}

.text-field-input :global(.MuiFormHelperText-root.Mui-error) {
  font: normal normal normal 12px/16px "Open Sans";
  letter-spacing: 0.4px;
  color: #b33322;
}

.text-field-input :global(.MuiFormHelperText-root) {
  font: normal normal normal 12px/16px "Open Sans";
  letter-spacing: 0.4px;
}
