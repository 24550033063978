.about-dialog-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 35px;
}

.about-dialog-content .left-side {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.about-dialog-content .smartsense-logo-and-name {
  display: flex;
  flex-direction: row;
}

.about-dialog-content .smartsense-logo-and-name svg {
  height: 18px;
  width: 18px;
}

.about-dialog-content .smartsense-name {
  margin-left: 5px;
  font: normal normal normal 14px/20px "Open Sans";
  letter-spacing: 0.25px;
  color: #173544;
}

.about-dialog-content .version-information {
  margin-top: 8px;
}

.about-dialog-content .version-data {
  font: normal normal normal 14px/20px Open Sans;
  letter-spacing: 0.25px;
  color: #666666;
}

.copyright {
  margin-top: 8px;
}

.about-dialog-content .right-side {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.about-dialog-content .ascom-logo-svg {
  fill: #e10a19;
  margin-top: 5px;
}

.about-dialog-content .ascom-information {
  margin-top: 8px;
}

.about-dialog-content .ascom-data {
  font: normal normal normal 14px/20px "Open Sans";
  letter-spacing: 0.25px;
  color: #666666;
}