.filter-input-body {
  width: 100%;
  height: 36px;
  box-sizing: border-box;
}

.value-input {
  width: 100%;
  margin: 0px;
  border: 0;
  padding: 0px;
  font: normal normal normal 14px/24px "Open Sans";
  letter-spacing: 0.13px;
  color: #000000;
  padding-left: 6px;
}

.value-input::placeholder {
  font: normal normal normal 14px/24px "Open Sans";
  letter-spacing: 0.13px;
  color: #00000066;
  opacity: 1;
}

.value-input.disabled {
  cursor: not-allowed;
  background: rgba(206, 217, 224, 1);
  color: rgba(92, 112, 128, 0.5);
  user-select: none;
}

.interaction-container {
  display: flex;
  border: 1px solid #d0d0d0;
  height: 100%;
  background-color: white;
  box-sizing: border-box;
}

.interaction-container.disabled {
  border: 1px solid #22b3b3;
  background: rgba(206, 217, 224, 1);
}

.interaction-container .icon {
  width: 24px;
  height: 100%;
  margin: 0 0 0 6px;
  display: flex;
  align-items: center;
}

.interaction-container .icon.disabled {
  background: rgba(206, 217, 224, 1);
}

.interaction-container .icon svg {
  fill: #666;
  width: 24px;
  height: 24px;
}

.interaction-container .icon.active svg {
  fill: #19838c;
}

.filter-input-body .filter-field-content-container {
  max-height: 400px;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  border: 1px solid #cfcfcf;
  border-top: 0;
  box-shadow: 0px 8px 10px #00000024;
  width: 100%;
  box-sizing: border-box;
  -webkit-transform: translate3d(0,0,0);
}

.filter-input-body .filter-field-content-container .item {
  background-color: white;
  max-width: 100%;
  padding: 13px 36px 13px 36px;
  user-select: none;
}

.filter-input-body .filter-field-content-container .item:hover {
  background-color: #00000014;
  filter: contrast(100%);
}

.filter-input-body .filter-field-content-container .item.keySelect {
  background-color: #00000014;
  filter: contrast(100%);
}

.interaction-container .close-button {
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 6px;
}

.interaction-container .close-button.disabled {
  background: rgba(206, 217, 224, 1);
}

.interaction-container .close-button svg {
  fill: black;
}

.close-button .MuiButtonBase-root {
  padding: 0;
}
