.select-input
  :global(.MuiAutocomplete-root .Mui-focused .MuiOutlinedInput-notchedOutline) {
  border-color: black;
}

.select-input :global(.MuiInputLabel-root.Mui-focused) {
  color: black;
}

.select-input
  :global(.MuiAutocomplete-root .Mui-error .MuiOutlinedInput-notchedOutline) {
  border-color: #b33322;
}

.select-input :global(.MuiInputLabel-root.Mui-focused.Mui-error) {
  color: #b33322;
}

.select-input :global(.MuiFormHelperText-root.Mui-error) {
  font: normal normal normal 12px/16px "Open Sans";
  letter-spacing: 0.4px;
  color: #b33322;
}

.field-label {
  overflow: hidden;
  text-overflow: ellipsis;
}
