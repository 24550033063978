.edit-peripheral {
  background: #f4f5f7;
}

.edit-peripheral td {
  border-top: 1px solid #19838c;
  border-bottom: 1px solid #19838c;
  border-left: 0;
  border-right: 0;
  vertical-align: top;
  padding-bottom: 20px;
  padding-top: 30px;
}

.edit-peripheral .action-column {
  padding-top: 22px;
}

.edit-peripheral td:first-child {
  border-left: 5px solid #19838c;
}

.edit-peripheral td:last-child {
  border-right: 1px solid #19838c;
}

.label {
  font-size: 13px;
  font-weight: 500;
  color: #606060;
  margin-bottom: 19px;
  margin-top: 29px;
}

.column {
  padding: 0 3px 0 3px;
}

.intent-danger {
  font-family: "Open Sans";
  margin-top: 5px;
  font-size: 12px;
  color: red;
}

.action-buttons {
  display: flex;
  flex-direction: column;
}

.used-column-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.action-buttons-container {
  display: flex;
  justify-content: center;
}
