.login-password-field {
  width: 100%;
  height: 50px;
}

.login-password-field :global(.input-field-body .input-field:hover) {
  border: none;
}

.login-password-field :global(.input-field-body .input-field.disabled) {
  border: none;
}

.login-password-field :global(.input-field-body .input-field:focus) {
  border: none;
  box-shadow: none;
}
