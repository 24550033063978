.calendar-button .calendar-icon {
  fill: #19838c;
}

.calendar-button .label {
  color: #19838c;
  font: normal normal 600 14px "Open Sans";
  letter-spacing: 1.25px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
}
