.edit-camera {
  background: #f4f5f7;
}

.edit-camera td {
  border-top: 1px solid #19838c;
  border-bottom: 1px solid #19838c;
  border-left: 0;
  border-right: 0;
  vertical-align: top;
  padding-top: 30px;
}

.edit-camera .action-column {
  padding-top: 22px;
}

.edit-camera td:first-child {
  border-left: 5px solid #19838c;
}

.edit-camera td:last-child {
  border-right: 1px solid #19838c;
}

.column {
  flex: 1;
  padding-right: 40px;
  width: 50%;
}

.add-location-button-container {
  margin-bottom: 5px;
  display: inline-block;
}

.add-location-button-container :global(.MuiButton-root) {
  font: normal normal 600 12px/16px "Open Sans";
  letter-spacing: 1.07px;
  color: #333333;
}

.clear-location-button {
  height: 38px;
  cursor: pointer;
  user-select: none;
  color: #22b3b3;
  display: flex;
  align-items: center;
  margin: 0 5px 0 5px;
}

.manage-cameras-distance-field {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  margin-top: 10px;
}

.edit-error-label {
  font-family: "Open Sans";
  margin: 5px 0 5px 0;
  font-size: 12px;
  color: red;
  height: 15px;
}

.edit-column-content {
  padding: 0 3px 0 3px;
}

.first-column {
  padding-left: 0;
  margin-left: 5px;
}

.distance-field {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  height: 55px;
}

.room-field {
  margin-bottom: 5px;
  height: 55px;
}

.action-buttons {
  display: flex;
  flex-direction: column;
}

.action-buttons-container {
  display: flex;
  justify-content: center;
}
