.edit-sensor {
  background: #f4f5f7;
  height: 106px;
}

.edit-sensor td {
  border-top: 1px solid #19838C;
  border-bottom: 1px solid #19838C;
  border-left: 0;
  border-right: 0;
  vertical-align: top;
  padding-top: 29px;
}

.edit-sensor td:first-child {
  border-left: 5px solid #19838C;
}

.edit-sensor td:last-child {
  border-right: 1px solid #19838C;
}

.label {
  font-size: 13px;
  font-weight: 500;
  color: #606060;
  margin-bottom: 19px;
  margin-top: 29px;
}

.column {
  padding: 3px 3px 3px 3px;
}

.first {
  padding-left: 51px;
}

.intent-danger {
  color: red;
  font-family: "Open Sans";
  font-size: 12px;
  margin-top: 5px;
}

.action-buttons {
  display: flex;
  flex-direction: column;
}

.edit-sensor .action-column {
  padding-top: 22px;
}

.action-buttons-container {
  display: flex;
  justify-content: center;
}
