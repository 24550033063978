.input-field {
  width: 100%;
  height: 100%;
  border: 0px;
  font-family: "Open Sans" !important;
  padding-left: 10px;
  box-sizing: border-box;
  outline: none;
  font-size: 14px;
  color: #182026;
}

.input-field:focus {
  border: 1px solid #719ece;
  box-shadow: 0 0 10px #719ece;
}
.input-field-body {
  height: 100%;
  border: 1px solid #dee2e6;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.input-field.diabled:hover {
  border: 1px solid #22b3b3;
}

.input-field.disabled {
  border: 1px solid #22b3b3;
  cursor: not-allowed;
  background: rgba(206,217,224,.5);
  color: rgba(92,112,128,.5);
}

.eye-icon{
  width: 20px;
  height: 12px;
  padding-right: 8px;
  padding-left: 8px;
}

.eye-icon svg{
  display: block;
}
