.status-group {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

.status-group :global(.disabled) {
  opacity: 0.4;
}

.status-group :global(.inactive) {
  fill: #b7b7b7;
  color: #b7b7b7;
}

.status-group :global(.delayed) {
  fill: #f4ae22;
}

.status-group :global(.instant) {
  fill: #f73b0f;
}
