.manage-alerts-grid-container {
    height: calc(100% - 62px);
    overflow: auto;
    overflow-x: hidden;
    margin: 0 8px 6px 8px;
    border: 1px solid #d0d0d0;
  }

  .overflow-content {
    overflow: auto;
    height: 100%;
  }
  