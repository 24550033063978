.title {
  font-size: 25px;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #212121;
  position: absolute;
  top: 30px;
  left: 46px;
}

.client-name {
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: #22b3b3;
  position: absolute;
  top: 70px;
  left: 46px;
}

.client-area {
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: #b2b2b2;
  position: absolute;
  top: 90px;
  left: 46px;
}

.client-room {
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: #404040;
}

.header-button {
  position: absolute;
  right: 32px;
  top: 22px;
  min-width: 120px;
  height: 40px;
}