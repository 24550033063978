.modal-dialog-component .confirmation-dialog {
  position: absolute;
  background-color: white;
  width: 500px;
  left: calc(50% - 250px);
  top: calc(50vh - 130px);
  border: 1px solid #D0D0D0;
  border-radius: 4px;
  z-index: 99999;
}

.modal-dialog-component .overlay {
  background-color: rgba(128, 128, 128, 0.8);
  top: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
}

.modal-dialog-component .title-container {
  display: flex;
  flex-direction: row;
  padding: 17px 11px 0 20px;
}

.modal-dialog-component .title {
  user-select: none;
  text-align: left;
  font: normal normal normal 20px/36px "Open Sans";
  letter-spacing: 0.16px;
  color: #333333;
  display: flex;
  align-items: center;
  width: 100%;
}

.modal-dialog-component .title-container .MuiIconButton-root {
  padding: 0;
}

.modal-dialog-component .body-container {
  padding: 20px 20px 30px 20px;
}

.modal-dialog-component .button-container {
  padding: 15px 20px 15px 20px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  color: #333333;
}

.modal-dialog-component .button-container .MuiButton-root {
  margin-left: 15px;
  font: normal normal 600 14px/16px "Open Sans";
  letter-spacing: 0.9px;
  color: #333333;
}

.modal-dialog-component .content {
  width: 100%;
  word-break: break-word;
  box-sizing: border-box;

  font: normal normal normal 16px/20px "Open Sans";
  letter-spacing: 0.13px;
  color: #666666;

  user-select: none;
  display: table;
  word-break: break-word;
}

.modal-dialog-component .content-message {
  display: table-cell;
  vertical-align: middle;
}

.modal-dialog-component .separator-line {
  background-color: #D0D0D0;
  width: 100%;
  height: 1px;
}

.modal-dialog-component .modal-button {
  border: 1px solid rgba(161, 165, 166, 0.38);
  border-radius: 5px;
  color: #8c9396;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
  user-select: none;
  min-width: 120px;
}

.modal-dialog-component .modal-button.ok-button {
  background-color: #22b3b3;
  color: #ffffff;
}

.modal-dialog-component .button-text {
  padding-left: 10px;
  padding-right: 10px;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 30px;
  padding-top: 5px;
}

.modal-dialog-component .modal-button.ok-button:hover {
  background: #7dbebe;
  color: white;
}

.modal-dialog-component .modal-button.ok-button:active {
  background: #258d8a;
  color: white;
}

.modal-dialog-component .cancel-button {
  border: 1px solid #8a9aa1;
  border-radius: 19px;
  min-width: 140px;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
  user-select: none;
}

.modal-dialog-component .modal-button:hover {
  background: white;
  border: 1px solid #22b3b3;
  color: #22b3b3;
}

.modal-dialog-component .modal-button:active {
  background: #258d8a;
  color: white;
}