.icon-tooltip-cell {
  width: 16px;
  height: 16px;
  fill: #19838c;
  cursor: pointer;
}

.tooltip {
  background-color: #173544;
  font: normal normal normal 14px/20px "Open Sans";
  letter-spacing: 0.11px;
  color: #FFFFFFCC;
  position: absolute;
  transform: translate(55%, 50%);
  z-index: 9999999;
  padding: 5px 10px 5px 10px;
  border-radius: 4px;
  line-height: 40px;
  min-height: 64px;
  min-width: 64px;
}

.icon-tooltip-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  justify-content: center;
}

.empty-list-text {
  white-space: nowrap;
  user-select: none;
}

.sensor-state-item {
    white-space: nowrap;
    display: flex;
}

.loader-sensor-id {
  width: 30px;
  height: 30px;
  border: 7px solid #f3f3f3;
  border-radius: 50%;
  border: 7px solid rgba(92, 112, 128, 0.2);
  border-top: 7px solid rgba(92, 112, 128, 0.8);
  -webkit-animation: spin 2s linear infinite;
  animation: spin-sensor-id 2s linear infinite;
  z-index: 1000;
  margin: 10px;
}

@-webkit-keyframes spin-sensor-id {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin-sensor-id {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}