.menu-button {
  cursor: pointer;
  width: 100%;
  background-color: #173544;
  display: flex;
  align-items: center;
  height: 70px;
}

.menu-button:hover {
  background-color: #18424F;
}

.menu-button-active,
.menu-button-active:hover {
  background-color: #184e5a;
  border-left: 4px solid #22b3b3;
  box-sizing: border-box;
}

.menu-button-disabled {
  pointer-events: none;
  opacity: 0.7;
}

.menu-button .button-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 18px;
}

.menu-button-active .button-content {
  margin: 14px;
}

.button-content .side-button-icon-container {
  width: 20px;
  height: 20px;
}

.button-content .side-button-icon-container svg {
  width: 20px;
  height: 20px;
  fill: white;
}

.button-content .side-button-icon-container svg {
  fill: #a2aeb4;
}

.menu-button-active .side-button-icon-container svg {
  fill: #ffffff;
}

.button-content .side-menu-text {
  margin-left: 10px;
  color: #a2aeb4;
  font-size: 14px;
  line-height: 24px;
  user-select: none;
}

.menu-button-active .side-menu-text {
  color: #ffffff;
}