.basic-header {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #2f2f2f;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0px;
  user-select: none;
}

.basic-header .basic-header-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.basic-header.center {
  justify-content: center;
}

.basic-header.left {
  justify-content: start;
}

.basic-header.right {
  justify-content: end;
}

.header-container {
  overflow: hidden;
}