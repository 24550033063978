.card-container {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-around;
  margin: 0 10px 0 10px;
}

.card-child-ghost {
  height: 240px;
  width: 280px;
  margin: 10px;
  visibility: hidden;
}
