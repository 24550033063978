.search-clients-input .search-area {
  font-size: 14px;
  letter-spacing: 0.4px;
  text-align: left;
  color: #b2b2b2;
  margin-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.search-clients-input .search-room {
  font-size: 14px;
  letter-spacing: 0.4px;
  text-align: left;
  color: #404040;
  text-transform: none;
  text-overflow: ellipsis;
  overflow: hidden;
}

.search-client-room {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  cursor: pointer;
  white-space: nowrap;
}

.search-client-name {
  font-size: 14px;
  letter-spacing: 0.4px;
  cursor: pointer;
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.selected-client {
  background-color: #eafbfb !important;
}

.selected-client td {
  border-top: solid 1px rgba(21, 53, 68, 0.38) !important;
  border-bottom: solid 1px rgba(21, 53, 68, 0.38) !important;
  border-left: 0;
  border-right: 0;
  padding-left: 3px;
  padding-right: 3px;
}

.selected-client td:first-child {
  padding-left: 0;
}

.selected-client td:last-child {
  padding-right: 0;
}
