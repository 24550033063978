.login-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.login-container {
  background: url("./svg/bg.png") no-repeat center center fixed, #22b3b3;
  background-size: cover;
  height: calc(100% - 40px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-container {
  height: 40px;
  width: 100%;
}

.form {
  width: 400px;
  min-height: 490px;
  background-color: #ffffff;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 57px;
}

.title {
  height: 41px;
  font-size: 30px;
  line-height: 41px;
  color: #000000;
  padding-left: 10px;
}

.sub-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
}

.sub-title {
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  word-break: break-word;
  text-align: center;
}

.input-box {
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 78px;
}

.input-box.username {
  padding-top: 30px;
}

.input-box.password {
  padding-top: 20px;
}

.label {
  color: #000000;
  font-size: 14px;
  line-height: 19px;
  height: 19px;
  padding-bottom: 10px;
}

.message {
  font-size: 14px;
  line-height: 19px;
  font-weight: 300;
  color: #000000;
  min-height: 38px;
  white-space: pre-line;
  text-align: center;
  word-break: break-word;
}

.message-container {
  padding: 10px 0 10px 0;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message.error {
  color: #e29463;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .login-container {
    background-size: contain;
  }
}
