.default-tab-button {
  height: 100%;
  background-color: #ffffff;
  display: flex;
  cursor: default;
  border: 1px solid #f6f6f6;
  user-select: none;
  min-width: 200px;
  max-width: 400px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.default-tab-button.inactive {
  background-color: #f6f6f6;
  cursor: pointer;
}

.default-tab-button .tab-text {
  color: #000000;
  font-size: 18px;
  line-height: 31px;
  letter-spacing: 0px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.default-tab-button.inactive .tab-text {
  font-weight: normal;
}

.tab-button-container {
  height: 100%;
  border: 1px solid #d0d0d0;
  box-sizing: border-box;
}