.location-cell {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  user-select: text;
}

.location-cell-areaName,
.location-cell-locationName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  letter-spacing: 0.4px;
}

.location-cell-areaName {
  color: #b2b2b2;
}

.location-cell-locationName {
  color: #404040;
}

.dash-icon {
    width: 40px;
    color: #173544;
}