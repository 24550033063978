.switch-input :global(.Mui-disabled .MuiSwitch-root) {
  opacity: 0.38;
}

.switch-input :global(.MuiFormControlLabel-root) {
  margin: 0;
  width: 100%;
}

.switch-input :global(.MuiTypography-root) {
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
