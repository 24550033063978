.clients-overview-header {
  width: 100%;
  height: 54px;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: row;
  position: sticky;
  z-index: 2;
  position: relative;
}

.clients-overview-tabs {
  margin: 7px 8px 0 8px;
  height: 48px;
}

.manage-clients-filter-inputs {
  margin-left: auto;
  padding-top: 10px;
  display: flex;
  flex-grow: 1;
  justify-content: end;
}

.inactive-clients-search {
  width: 100%;
  margin-right: 8px;
  display: flex;
  justify-content: end;
  max-width: 265px;
}

.add-button-container {
  margin-right: 8px;
  margin-left: 4px;
}
