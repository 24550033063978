.label {
  margin-bottom: 19px;
  font-size: 13px;
  font-weight: 500;
  color: #606060;
  user-select: none;
}

.intent-danger {
  margin-top: 5px;
  height: 15px;
  font-size: 12px;
  color: red;
}
