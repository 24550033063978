.client-dashboard-content {
  width: 100%;
  height: calc(100% - 62px);
  overflow-x: hidden;
  overflow-y: auto;
}

.dashboard-layout {
  display: flex;
  justify-content: space-around;
  min-height: 530px;
  margin: 0px 30px 0px 30px;
}

.content {
  flex: 6;
  flex-grow: 6;
  display: flex;
  flex-direction: column;
}

.daily-view {
  min-height: 150px;
}
