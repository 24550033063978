.clients-overview-grid-container {
  height: calc(100% - 62px);
  overflow: auto;
  overflow-x: hidden;
  margin: 0 8px 6px 8px;
  border: 1px solid #d0d0d0;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.clients-overview-grid-container :global(.firstColumn) {
  margin-left: 45px;
}

.clients-overview-grid-container :global(.firstColumn .header-cell-container) {
  margin-left: 42px;
}

.remove-container {
  background-color: pink;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-around;
}

.remove-child {
  height: 240px;
  width: 280px;
  background-color: aqua;
  margin: 15px;
}

.remove-child-ghost {
  height: 240px;
  width: 280px;
  margin: 15px;
  visibility: hidden;
}

.clients-overview-grid-buttons-container {
  display: flex;
  height: 36px;
  margin: 15px 20px 15px 20px;
}

.clients-overview-content-container{
  flex-grow: 1;
  overflow: hidden;
}

.time-toggle {
  margin-right: 12px;
}

.overflow-content {
  overflow: auto;
  height: 100%;
}
