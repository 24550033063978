.amstel-time-picker {
  display: block;
  user-select: none;
}

.amstel-time-picker .columns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.amstel-time-picker .col {
  margin: 0;
}

.amstel-time-picker .col.margins {
  margin: 0 15px;
}

.amstel-time-picker .button {
  font-size: 24px;
  margin: 4px auto;
  text-align: center;
}

.amstel-time-picker .active {
  color: #22b3b3;
  cursor: pointer;
}

.amstel-time-picker .disabled {
  color: #c7c9ca;
  cursor: default;
}

.amstel-time-picker div.input {
  width: 50px;
  height: 40px;
  mix-blend-mode: undefined;
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px rgba(222, 226, 230, 0.38);

  line-height: 40px;
  text-align: center;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.4px;
  color: #606060;
}
