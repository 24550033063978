.card-item-container {
  width: 280px;
  height: 240px;
  background-color: white;
  box-shadow: 0px 1px 3px #00000033;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 10px;
  user-select: none;
  box-sizing: border-box;
}

.card-item-container.highlight {
  background-color: #f0f7f7;
  border: 1px solid #19838c;
}

.header {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: end;
}

.name-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-right: 16px;
}

.location {
  font-size: 12px;
  letter-spacing: 0.21px;
  color: #00000099;
}

.name {
  font-size: 16px;
  letter-spacing: 0.12px;
  color: #000000de;
  margin-top: -2px;
}

.warning-icon {
  display: flex;
  align-self: start;
  margin: 14px 14px 0 auto;
  cursor: pointer;
}

.warning-icon.red svg {
  fill: #d23c29;
}

.warning-icon.yellow svg {
  fill: #f4ae22;
}

.icon-container {
  width: 32px;
  height: 32px;
  background-color: #e4e5e6;
  border-radius: 50%;
  margin: 0 16px 0 16px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: black;
  font-weight: bold;
  letter-spacing: 0;
}

.content {
  height: 140px;
  width: 100%;
}

.content-header {
  display: flex;
  justify-content: center;
  margin: 0 16px 0 16px;
}

.content-alert-icons {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-icon-container {
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status.alert {
  color: #d23c29;
}

.status.delayed {
  color: #f4ae22;
}

.card-item-container :global(.status-icon) {
  width: 40px;
  height: 40px;
  margin: 0;
}

.card-item-container :global(.status-icon) svg {
  width: 40px;
  height: 40px;
}

.status {
  font-size: 24px;
  letter-spacing: 0px;
  color: #153544;
  margin-top: -4px;
}

.status-timestamp {
  font-size: 12px;
  letter-spacing: 0.29px;
  color: #00000099;
}

.status-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-status {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
}

.second-status-container {
  display: flex;
  flex-direction: row;
}

.second-status {
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000de;
  margin-right: 3px;
  overflow: hidden;
}

.second-status-timestamp {
  font-size: 12px;
  letter-spacing: 0.21px;
  color: #00000099;
  letter-spacing: 0.21px;
  white-space: nowrap;
}

.content-footer {
  height: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.nurse-container {
  display: flex;
  background-color: #19838c24;
  border-radius: 12px;
  height: 24px;
  align-items: center;
}

.nurse-status {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.77px;
  color: #19838c;
  margin-right: 8px;
}

.nurse-icon {
  margin: 0 8px 0 3px;
  display: flex;
}

.nurse-icon svg {
  overflow: visible;
}

.footer {
  height: 50px;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #d0d0d0;
}
