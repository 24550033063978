.ReactTable {
  border: none;
  min-height: 100%;
}

.ReactTable .rt-resizer {
  width: 12px;
  right: 0px;
}

.ReactTable .rt-tbody {
  overflow: hidden;
}

.ReactTable.-highlight {
  border: none;
}

.ReactTable .rt-thead.-header {
  box-shadow: none;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td,
.ReactTable .rt-tbody .rt-td {
  border: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-top: 0;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: none;
  color: #22b3b3;
  font-weight: bold;
  fill: #22b3b3;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none;
  color: #22b3b3;
  font-weight: bold;
  fill: #22b3b3;
}

.ReactTable .rt-thead.-header {
  height: 68px;
}

.ReactTable .rt-thead .rt-th {
  height: 68px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #606060;
  text-transform: uppercase;
  text-align: left;
  background-color: #edf0f2;
  line-height: 20px;
  padding-top: 25px;
}

.ReactTable .rt-tr {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: #404040;
}

.ReactTable .rt-tr .rt-td {
  height: 60px;
  max-height: 60px;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rt-table {
  background-color: #fff;
}

.ReactTable .rt-tbody .rt-tr-group {
  border: none;
  flex: none;
}

.ReactTable .rt-tbody .rt-tr {
  box-sizing: border-box;
  border-left: 0;
  border-right: 0;
  border-top: 1px solid #fff;
  border-bottom: 1px solid rgba(215, 215, 215, 0.38);
}

.mobile-client-data {
  color: #999;
}

.mobile-room-data,
.mobile-status-data,
.mobile-time-data {
  display: inline;
  padding: 0 2rem 0 2rem;
}
