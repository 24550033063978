.cancel-button :global(.MuiButtonBase-root) {
  font: normal normal 600 14px/16px Open Sans;
  letter-spacing: 1.25px;
  color: #173544;
}

.cancel-button :global(.MuiButton-startIcon) {
  margin-right: 4px;
}

.cancel-button {
  display: flex;
}
