.simple-button {
  width: 100%;
  height: 100%;
  background-color: cyan;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.simple-button.active:hover {
  filter: brightness(110%);
}

.simple-button.active:active {
  filter: brightness(85%);
}

.simple-button.disabled {
  background-color: grey;
  cursor: default;
}

.simple-button .button-text {
  display: inline-block;
  color: #ffffff;
  user-select: none;
}
