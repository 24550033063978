.delay-col {
  width: 50%;
  float: left;
  display: flex;
}

.label {
  float: left;
  padding-right: 20px;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  -webkit-text-fill-color: #d3d3d3;
  color: #999999;
}

.invisible {
  visibility: hidden;
}

.alert-readonly-settings-value {
  width: 64px;
  height: 34px;
  font-size: 14px;
  user-select: none;
  padding-top: 10px;
}
