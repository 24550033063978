.save-button :global(.MuiButtonBase-root) {
  font: normal normal 600 14px/16px Open Sans;
  letter-spacing: 1.25px;
  color: #19838c;
}

.save-button :global(.MuiButton-startIcon) {
  margin-right: 4px;
}

.save-button svg {
  fill: #19838c;
}

.save-button {
  display: flex;
}
