@font-face {
  font-family: "Open Sans";
  src: url("../fonts/open-sans/open-sans-v34-latin-regular.woff2")
      format("woff2"),
    url("../fonts/open-sans/open-sans-v34-latin-regular.woff") format("woff"),
    url("../fonts/open-sans/open-sans-v34-latin-regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/open-sans/open-sans-v34-latin-600.woff2") format("woff2"),
    url("../fonts/open-sans/open-sans-v34-latin-600.woff") format("woff"),
    url("../fonts/open-sans/open-sans-v34-latin-600.ttf") format("truetype");
  font-weight: 600;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

#react-app {
  height: 100%;
}

html.no-scroll {
  overflow: hidden;
}

body {
  font-family: "Open Sans";
  letter-spacing: 0.4px;
  color: #777;
  background-color: #f3f5f7;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

div.layout {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

div.mari-padding {
  padding-right: 60px;
}

.main {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: overlay;
  min-width: 774px;
}

/* Firefox puts an inner border on buttons*/
*:focus {
  border: 0px;
  outline: none;
}

*::-moz-focus-inner {
  border: 0px;
  outline: none;
}

.layout-content-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: auto;
}

.layout {
  display: flex;
  flex-direction: column;
}