.time-alerts {
  height: 32px;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #404040;
  padding: 0;
  display: flex;
  flex-direction: row;
}

.time-interval-hours {
  color: #b2b2b2;
  white-space: pre;
  user-select: none;
}

.time-interval-title {
  font-weight: normal;
  font-size: 14px;
  white-space: pre;
  user-select: none;
}

.selected-interval-title {
  font-weight: bold;
  font-size: 15px;
  white-space: pre;
  user-select: none;
}

.input-radio {
  cursor: pointer;
}

.label-container {
  cursor: pointer;
}
