.substatus-name {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-align: left;
  color: #404040;
  text-transform: none;
  text-overflow: ellipsis;
  overflow: hidden;
}

.selected-substatus {
  background-color: #eafbfb !important;
}

.selected-substatus td {
  border-top: solid 1px rgba(21, 53, 68, 0.38) !important;
  border-bottom: solid 1px rgba(21, 53, 68, 0.38) !important;
  border-left: 0;
  border-right: 0;
  padding-left: 3px;
  padding-right: 3px;
}

.selected-substatus td:first-child {
  padding-left: 0;
}

.selected-substatus td:last-child {
  padding-right: 0;
}

.status-icon {
    fill: #153544;
    display: flex;
    align-items: center;
    user-select: none;
}