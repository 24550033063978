.tabs-container {
  width: 100%;
}

.item {
  width: 100%;
  mix-blend-mode: undefined;
  background-color: #ffffff;
  border: solid 1px rgba(215, 215, 215, 0.38);
}

.odd-tab {
  height: 68px;
  background-color: #edf0f2;
}

.title {
  position: inherit;
  padding: 24px 24px 24px 48px;
  font-weight: 500;
  font-size: inherit;
  letter-spacing: 0.4px;
  font-stretch: normal;
  color: #606060;
  top: 30px;
  left: 46px;
  line-height: normal;
}

.even-tab {
  display: flex;
  padding-left: 48px;
  min-height: 60px;
  mix-blend-mode: undefined;
  background-color: #ffffff;
  border: solid 1px rgba(215, 215, 215, 0.38);
}

.text {
  margin: auto 0;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.4px;
  font-stretch: normal;
  color: #606060;
  white-space: nowrap;
}

.toggle-button {
  object-fit: contain;
}

.col-1 {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.col-2 {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  -webkit-text-fill-color: #d3d3d3;
}

.col-4 {
  padding: 10px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.default-icon {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.inactive-status-icon {
  margin-right: 5px;
  fill: #b7b7b7;
}

.col-3 {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row-1 {
  min-height: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  align-items: start;
}

.row-1.edit-text {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.row-2 {
  min-height: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.row-2.edit-text {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.status-group {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

.status-group :global(.disabled) {
  opacity: 0.4;
}

.status-group :global(.inactive) {
  fill: #b7b7b7;
  color: #b7b7b7;
}

.status-group :global(.delayed) {
  fill: #f4ae22;
}

.status-group :global(.instant) {
  fill: #f73b0f;
}

.switch-container {
  display: flex;
  margin: -12px;
}
