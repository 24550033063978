.last-days {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: solid 1px rgba(215, 215, 215, 0.38);
  margin-bottom: 15px;
}

.column {
  flex: 1;
}

.column:nth-child(2) {
  height: 250px;
}

.chart-title {
  font-family: "Open Sans";
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: left;
  color: #160940;
  text-transform: uppercase;
  margin-left: 16px;
  margin-top: 16px;
  display: block;
}

text {
  font-size: 10px;
  letter-spacing: 0.3px;
  color: #b2b2b2;
}

:global(.recharts-legend-wrapper) {
  left: 17px !important;
}

ul.recharts-default-legend {
  list-style-type: none;
  padding: 0;
  margin: 0 0 17px 0;
}

.recharts-legend-item {
  font-size: 10px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}
