.client-name-cell {
  font-size: 16px;
  letter-spacing: 0.4px;
  color: #22b3b3;
  height: 60px;
  display: flex;
  max-width: 100%;
  position: relative;
}

.client-cell-icon {
  width: 45px;
  min-width: 45px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -45px;
}

.client-cell-icon.warning {
  fill: #f4ae22;
}

.client-cell-icon.alert {
  fill: #f73b0f;
}

.client-link {
  cursor: pointer;
  align-items: center;
  display: inline-grid;
}

.client-link-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
