.statuses-panel {
  background: #fff;
  margin-top: 0px;
}

.status-panel-content {
  border: solid 1px rgba(215, 215, 215, 0.38);
  max-height: 646px;
  height: 585px;
  width: 150px;
  overflow-x: hidden;
  padding-left: 12px;
  padding-right:12px;
  margin: 0;
}

.status-panel-content li {
  list-style: none;
}

@media (min-height: 680px) {
  .status-panel-content {
    height: 585px;
  }
}
