.toggle-button-group-container :global(.MuiButtonBase-root) {
  background-color: #ffffff;
  color: #173544;
  padding: 8px 16px 8px 16px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.11px;
  text-transform: none;
  border: 1px solid #173544;
  height: 32px;
}

.toggle-button-group-container
  :global(
    .MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type)
  ) {
  margin-left: 0;
}

.toggle-button-group-container :global(.MuiButtonBase-root:hover) {
  background-color: #ffffff;
}
.toggle-button-group-container :global(.MuiButtonBase-root) svg {
  fill: #173544;
}

.toggle-button-group-container :global(.MuiButtonBase-root.Mui-selected) {
  background-color: #173544;
  color: #ffffff;
}
.toggle-button-group-container :global(.MuiButtonBase-root.Mui-selected:hover) {
  background-color: #173544;
}

.toggle-button-group-container :global(.MuiButtonBase-root.Mui-selected) svg {
  fill: white;
}

.toggle-button-group-container :global(.MuiButtonBase-root) .text {
  fill: white;
  margin-right: 14px;
}

.button-container {
  display: flex;
  align-items: center;
}
